<template>
      <div class="bordaappbar">
            <v-row class="mt-15">
                  <v-col cols="8" offset="1">
                        <v-select model="sInferencia" :items="sListaInferencia" label="Selecione uma sugestão" outlined dense @change="updateTextarea"></v-select>
                  </v-col>
            </v-row>
            <v-row class="mb-15">
                  <v-col cols="8" offset="1">
                        <v-textarea hide-details dense outlined label="Inferência" v-model="sInferencia" textarea rows="4" auto-grow no-resize></v-textarea>
                  </v-col>
                  <v-col cols="2" offset="0">
                        <v-btn @click="Inference()" color="primary">
                              Inferir
                              <v-icon>mdi-location-enter</v-icon>
                        </v-btn>
                  </v-col>
            </v-row>
            <v-row v-if="responseData.length > 0">
                  <v-col cols="10" offset="1" class="text-center">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="exportToPDF">Export to PDF</v-btn>
                        <v-spacer></v-spacer>
                  </v-col>
            </v-row>
            <v-row v-if="responseData.length > 0" class="mb-15">
                  <v-col cols="10" offset="1">
                        <v-data-table class="elevation-1" :headers="headers" :items="responseData" item-value="value" :items-per-page="100">
                              <template v-slot:body="{ items }">
                                    <tr v-for="(item, index) in items" :key="item.id" class="v-data-table__tr row-pointer" :style="getRowStyle(index)">
                                          <td v-for="header in headers" :key="header.value" class="v-data-table__td">
                                                {{ formatarValor(item[header.value]) }}
                                          </td>
                                    </tr>
                              </template>
                        </v-data-table>
                  </v-col>
            </v-row>
      </div>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
      name: "Lancamento",
      data() {
            return {
                  sInferencia: "",
			sListaInferencia: ["Quais os casos que foram faturados ao cliente, mas ainda não foram pagos", "Qual a relação dos nomes e e-mails dos advogados", "Crie uma lista de Casos e seus advogados responsáveis", "Liste os lançamentos digitados pelo advogado ??nome?? em ??mês?? de ??ano??", "Quais as faturas que ainda não foram pagas em ??mes?? de ??ano??"],
                  responseData: [],
                  headers: [],
                  items: [],
            };
      },
      components: {},
      methods: {
            updateTextarea(value) {
                  // Atualiza o v-textarea com o valor selecionado no v-select
                  this.sInferencia = value;
            },
            async Inference() {
                  try {
                        const response = await axios.post("http://localhost:8081/inference", { text: this.sInferencia });
                        const jsonData = JSON.parse(response.data.json);
                        this.responseData = jsonData;
                        this.processData(jsonData);
                  } catch (error) {
                        console.error("Erro na inferência:", error);
                        this.responseData = "Erro ao processar a solicitação.";
                  }
            },

            formatarValor(value) {
                  if (typeof value === "number") {
                        return value.toFixed(2);
                  }
                  return value;
            },

            getRowStyle(index) {
                  return index % 2 === 0 ? { backgroundColor: "#ffffff" } : { backgroundColor: "#eeeeee" };
            },

            processData(jsonData) {
                  if (Array.isArray(jsonData) && jsonData.length > 0) {
                        // Filtra as chaves que não começam com "codigo"
                        this.headers = Object.keys(jsonData[0])
                              .filter((key) => !key.startsWith("codigo")) // Exclui as chaves que começam com "codigo"
                              .map((key) => ({
                                    text: key, // Nome do cabeçalho
                                    value: key, // Chave usada para acessar os dados
                                    width: "auto", // Largura fixa de 70px
                                    // sortable: true, // Desabilita a ordenação
                              }));
                  }

                  //   console.log(this.headers);
            },

            exportToPDF() {
                  const doc = new jsPDF("landscape");

                  // Configurar cabeçalhos e alinhamento
                  const tableHeaders = this.headers.map((header) => ({
                        content: header.text,
                        styles: { halign: header.align || "left" },
                  }));

                  // Configurar os dados
                  const tableRows = this.responseData.map((item) =>
                        this.headers.map((header) => ({
                              content: item[header.value],
                              styles: { halign: header.align || "left" },
                        }))
                  );

                  // Configurar estilos e gerar o PDF
                  doc.text("Data Table Export", 14, 10);
                  doc.autoTable({
                        head: [tableHeaders], // Cabeçalhos com alinhamento personalizado
                        body: tableRows, // Linhas com dados
                        theme: "grid", // Estilo de tabela (grid para bordas)
                        headStyles: { fillColor: [41, 128, 185], textColor: 255, fontSize: 10 }, // Estilo dos cabeçalhos
                        bodyStyles: { fontSize: 9 }, // Estilo das células
                        columnStyles: this.headers.reduce((styles, header, index) => {
                              styles[index] = { cellWidth: header.width || "auto" };
                              return styles;
                        }, {}),
                        startY: 20,
                  });

                  doc.save("data-table.pdf");
            },
      },
      created() {},
};
</script>

<style scoped>
.v-data-table__td {
      color: inherit !important;
      padding: 8px;
      vertical-align: top;
}

.v-data-table__tr {
      color: inherit !important;
      padding: 8px;
      vertical-align: top;
}
.row-pointer {
      cursor: pointer;
}
</style>
