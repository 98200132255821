<template>
      <div class="bordaappbar">
            <v-data-table class="elevation-1 row-pointer" @click:row="RowSelectClick" :headers="Header" mobile-breakpoint="1" :items="GridData" :item-class="RowClass" :sort-by.sync="Pagination.sortBy" :must-sort="true" :sort-desc.sync="Pagination.sortDesc" item-key="Id" :options.sync="Pagination" :server-items-length="Pagination.itemsLength" :footer-props="{ itemsPerPageOptions: RowsPerPageItems }" v-on:update:page="Index()" v-on:update:items-per-page="Index()" v-on:update:sort-by="Index()" v-on:update:sort-desc="Index()">
                  <template v-slot:top>
                        <v-toolbar dense flat class="mt-5">
                              <v-col col="8" md="6" lg="6" offset="0" offset-md="2" offset-lg="2">
                                    <v-text-field hide-details dense outlined prepend-inner-icon="mdi-magnify" label="Procurar" v-model="Pagination.Filtro.Busca" @change="Index()"></v-text-field>
                              </v-col>
                              <v-spacer></v-spacer>
                              <v-btn to="/Lancamento/Edit/0" color="primary" v-if="False">
                                    Nova
                                    <v-icon>mdi-plus</v-icon>
                              </v-btn>
                        </v-toolbar>
                        <br />
                  </template>

                  <template v-slot:[`item.DataLancamento`]="{ item }">
                        <span class="text-no-wrap">
                              {{ item.DataLancamento | dateBR }}
                        </span>
                  </template>

                  <template v-slot:[`item.ValorLancamentoCalculado`]="{ item }">
                        <span class="text-no-wrap">
                              {{ FormataMoeda(item.ValorLancamentoCalculado, item.MoedaFaturamento) }}
                        </span>
                  </template>

                  <template v-slot:[`item.MoedaFaturamento`]="{ item }">
                        <span class="text-no-wrap">
                              <span v-if="item.MoedaFaturamento == 1">R$</span>
                              <span v-if="item.MoedaFaturamento == 2">U$</span>
                        </span>
                  </template>

                  <template v-slot:[`item.Action`]="{ item }" v-if="False">
                        <v-icon @click="Edit(item.Id)" color="teal">mdi-pencil</v-icon>
                  </template>
            </v-data-table>
      </div>
</template>

<script>
import axios from "axios";

export default {
      name: "Lancamento",
      data() {
            return {
                  GridData: [],
                  Header: [
                        { text: "Id", value: "Id", width: "100", sortable: false },
                        { text: "Data", value: "DataLancamento", width: "100", sortable: false },
                        { text: "Cliente", value: "Cliente.Nome", width: "20%", sortable: false },
                        { text: "Caso", value: "Caso.Nome", width: "20%", sortable: false },
                        { text: "Advogado", value: "Advogado.Nome", width: "15%", sortable: false },
                        { text: "Descrição", value: "Descricao", width: "25%", sortable: false },
                        { text: "Horas", value: "Horas", sortable: false },
                        // { text: "Moeda", value: "MoedaFaturamento", sortable: false },
                        { text: "Valor", value: "ValorLancamentoCalculado", sortable: false },
                        { text: "", value: "Action", sortable: false },
                  ],
                  RowsPerPageItems: [5, 10, 20, 30, 40, 50, 100, 200],
                  Pagination: {
                        page: 1,
                        itemsPerPage: 50,
                        pageCount: 1,
                        itemsLength: 0,
                        sortBy: ["DataLancamento"],
                        sortDesc: [true],
                        Filtro: {
                              Busca: "",
                              FlagLancamento: "P",
                              FlagFCDG: 0,
                        },
                  },
            };
      },
      components: {},
      methods: {
            Edit(Id) {
                  this.$router.push("/Lancamento/Edit/".concat(Id));
            },
            Index() {
                  //Pega os dados da controller, action Index
                  axios.post("/api/Lancamentos/Index", this.Pagination).then((response) => {
                        //Pega os dado do Grid
                        this.GridData = response.data.Data;

                        //Pega os dados da Paginação
                        this.Pagination = response.data.Pagination;
                  });
            },
            FormataMoeda(valor, moeda) {
                  var sPreMoeda = "";
                  if (moeda == 1) {
                        sPreMoeda = "R$ ";
                  } else if (moeda == 2) {
                        sPreMoeda = "U$ ";
                  }
                  return sPreMoeda.concat(
                        parseFloat(valor)
                              .toFixed(2)
                              .replace(".", ",")
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                  );
            },
            RowClass(item) {
                  if (item.Ativo == false) {
                        return "rowdisabled";
                  }
            },
            // RowSelect para edição
            RowSelectClick: function (item, row) {
                  this.$router.push("/Lancamento/Edit/".concat(item.Id));
            },
      },
      mounted: function () {
            this.Index();
      },
};
</script>
