import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

// Importação dos módulos para depois ser configuradas as rotas

//Home do Sistema
import Home from '../views/Home.vue'

//Cadastro
import CadastroPessoaIndex from '../views/Cadastro/Pessoa/PessoaIndex.vue'
import CadastroPessoaEdit from "../views/Cadastro/Pessoa/PessoaEdit.vue";

import CadastroAdvogadoIndex from "../views/Cadastro/Advogado/AdvogadoIndex.vue";
import CadastroAdvogadoEdit from "../views/Cadastro/Advogado/AdvogadoEdit.vue";

import CadastroClienteIndex from "../views/Cadastro/Cliente/ClienteIndex.vue";
import CadastroClienteEdit from "../views/Cadastro/Cliente/ClienteEdit.vue";

import CadastroTarefaIndex from "../views/Cadastro/Tarefa/TarefaIndex.vue";
import CadastroTarefaEdit from "../views/Cadastro/Tarefa/TarefaEdit.vue";

import CadastroContatoIndex from "../views/Cadastro/Contato/ContatoIndex.vue";
import CadastroContatoEdit from "../views/Cadastro/Contato/ContatoEdit.vue";

import CadastroCasoIndex from "../views/Cadastro/Caso/CasoIndex.vue";
import CadastroCasoEdit from "../views/Cadastro/Caso/CasoEdit.vue";

// Lancamento
import CadastroLancamentoIndex from "../views/Lancamento/LancamentoIndex.vue";
import CadastroLancamentoEdit from "../views/Lancamento/LancamentoEdit.vue";

// Preliminar
import CadastroPreliminarIndex from "../views/Preliminar/PreliminarIndex.vue";
import CadastroPreliminarEdit from "../views/Preliminar/PreliminarEdit.vue";

// Faturamento
import CadastroFaturamentoIndex from "../views/Faturamento/FaturamentoIndex.vue";
import CadastroFaturamentoEdit from "../views/Faturamento/FaturamentoEdit.vue";
import GerarFaturamento from "../views/Faturamento/GerarFaturamento.vue";

// Despesa
import CadastroDespesaIndex from "../views/Despesa/DespesaIndex.vue";
import CadastroDespesaEdit from "../views/Despesa/DespesaEdit.vue";

// Comissionamento
import ComissionamentoIndex from "../views/Comissionamento/ComissionamentoIndex.vue";

// Comissionamento
import IAIndex from "../views/IA/Inferencia.vue";

// Importação
import ImportacaoIndex from "../views/Importacao/ImportacaoIndex.vue";

//Configurações do Sistema
import SistemaParametroIndex from '../views/Sistema/Parametros/SistemaParametrosIndex.vue'
import SistemaParametroEdit from '../views/Sistema/Parametros/SistemaParametrosEdit.vue'

//Controllers
import SistemaControllerIndex from '../views/Sistema/Controller/SistemaControllerIndex.vue'
import SistemaControllerEdit from '../views/Sistema/Controller/SistemaControllerEdit.vue'

//Grupos
import SistemaGrupoIndex from '../views/Sistema/Grupo/SistemaGrupoIndex.vue'
import SistemaGrupoEdit from '../views/Sistema/Grupo/SistemaGrupoEdit.vue'

import SistemaGrupoUsuariosIndex from "../views/Sistema/GrupoUsuarios/SistemaGrupoUsuariosIndex.vue";
import SistemaGrupoUsuariosEdit from "../views/Sistema/GrupoUsuarios/SistemaGrupoUsuariosEdit.vue";

import SistemaGrupoControllerIndex from "../views/Sistema/GrupoController/SistemaGrupoControllerIndex.vue";
import SistemaGrupoControllerEdit from "../views/Sistema/GrupoController/SistemaGrupoControllerEdit.vue";

//Usuarios
import SistemaUsuarioIndex from '../views/Sistema/Usuario/SistemaUsuarioIndex.vue'
import SistemaUsuarioEdit from '../views/Sistema/Usuario/SistemaUsuarioEdit.vue'

//Controle de Menu
import SistemaMenuIndex from '../views/Sistema/Menu/SistemaMenuIndex.vue'
import SistemaMenuEdit from '../views/Sistema/Menu/SistemaMenuEdit.vue'

//Permissões
import SistemaPermissaoIndex from '../views/Sistema/Permissao/SistemaPermissaoIndex.vue'
import SistemaPermissaoEdit from '../views/Sistema/Permissao/SistemaPermissaoEdit.vue'

//Páginas Auxiliares
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import About from '../views/About.vue'
import Denied from '../views/Denied.vue'
import Error from '../views/Error.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)


//Cadatro das Rotas do VueJS
const routes = [
      //Home do Sistema
      { path: "/", redirect: "/home", meta: { title: "Página Inicial" } },
      { path: "/home", name: "home", component: Home, meta: { title: "Página Inicial" } },

      // Cadastro
      { path: "/Cadastro/Pessoas/Index", name: "CadastroPessoaIndex", component: CadastroPessoaIndex, meta: { title: "CADASTRO PESSOA" } },
      { path: "/Cadastro/Pessoas/Edit/:id", name: "CadastroPessoaEdit", component: CadastroPessoaEdit, meta: { title: "CADASTRO PESSOA - EDITAR" } },

      { path: "/Cadastro/Clientes/Index", name: "CadastroClienteIndex", component: CadastroClienteIndex, meta: { title: "CADASTRO CLIENTE" } },
      { path: "/Cadastro/Clientes/Edit/:id", name: "CadastroClienteEdit", component: CadastroClienteEdit, meta: { title: "CADASTRO CLIENTE - EDITAR" } },

      { path: "/Cadastro/Advogados/Index", name: "CadastroAdvogadoIndex", component: CadastroAdvogadoIndex, meta: { title: "CADASTRO ADVOGADO" } },
      { path: "/Cadastro/Advogados/Edit/:id", name: "CadastroAdvogadoEdit", component: CadastroAdvogadoEdit, meta: { title: "CADASTRO ADVOGADO - EDITAR" } },

      { path: "/Cadastro/Tarefas/Index", name: "CadastroTarefaIndex", component: CadastroTarefaIndex, meta: { title: "CADASTRO TAREFA" } },
      { path: "/Cadastro/Tarefas/Edit/:id", name: "CadastroTarefaEdit", component: CadastroTarefaEdit, meta: { title: "CADASTRO TAREFA - EDITAR" } },

      { path: "/Cadastro/Contatos/Index", name: "CadastroContatoIndex", component: CadastroContatoIndex, meta: { title: "CADASTRO CONTATO" } },
      { path: "/Cadastro/Contatos/Edit/:id", name: "CadastroContatoEdit", component: CadastroContatoEdit, meta: { title: "CADASTRO CONTATO - EDITAR" } },

      { path: "/Cadastro/Casos/Index", name: "CadastroCasoIndex", component: CadastroCasoIndex, meta: { title: "CADASTRO CASO" } },
      { path: "/Cadastro/Casos/Edit/:id", name: "CadastroCasoEdit", component: CadastroCasoEdit, meta: { title: "CADASTRO CASO - EDITAR" } },

      // Lancamento
      { path: "/Lancamento/Index", name: "CadastroLancamentoIndex", component: CadastroLancamentoIndex, meta: { title: "CADASTRO LANCAMENTO" } },
      { path: "/Lancamento/Edit/:id", name: "CadastroLancamentoEdit", component: CadastroLancamentoEdit, meta: { title: "CADASTRO LANCAMENTO - DETALHE" } },

      // Preliminar
      { path: "/Preliminar/Index", name: "CadastroPreliminarIndex", component: CadastroPreliminarIndex, meta: { title: "PRELIMINAR" } },
      { path: "/Preliminar/Edit/:id", name: "CadastroPreliminarEdit", component: CadastroPreliminarEdit, meta: { title: "PRELIMINAR - DETALHE" } },

      // Comissionamento
      { path: "/Comissionamento/Index", name: "ComissionamentoIndex", component: ComissionamentoIndex, meta: { title: "COMISSIONAMENTO" } },

      // IA
      { path: "/IA/Inferencia", name: "IAInferencia", component: IAIndex, meta: { title: "INFERÊNCIA - IA" } },

      // Faturamento
      { path: "/Faturamento/Index", name: "CadastroFaturamentoIndex", component: CadastroFaturamentoIndex, meta: { title: "FATURAS" } },
      { path: "/Faturamento/Edit/:id", name: "CadastroFaturamentoEdit", component: CadastroFaturamentoEdit, meta: { title: "FATURAS - DETALHE" } },
      { path: "/Faturamento/GerarFaturamento", name: "GerarFaturamento", component: GerarFaturamento, meta: { title: "GERAR FATURAMENTO" } },

      // Despesa
      { path: "/Despesa/Index", name: "CadastroDespesaIndex", component: CadastroDespesaIndex, meta: { title: "CADASTRO DESPESA" } },
      { path: "/Despesa/Edit/:id", name: "CadastroDespesaEdit", component: CadastroDespesaEdit, meta: { title: "CADASTRO DESPESA - DETALHE" } },

      // Importação
      { path: "/Importacao/Index", name: "ImportacaoIndex", component: ImportacaoIndex, meta: { title: "IMPORTAÇÃO DE DADOS - CLICKTIME" } },

      //Configurações do Sistema
      { path: "/Sistema/Parametros/Index", name: "SistemaParametroIndex", component: SistemaParametroIndex, meta: { title: "PARÂMETROS" } },
      { path: "/Sistema/Parametros/Edit/:id", name: "SistemaParametroEdit", component: SistemaParametroEdit, meta: { title: "PARÂMETROS - EDITAR" } },

      //Cadastro das Cotnrollers
      { path: "/Sistema/Controllers/Index", name: "SistemaControllerIndex", component: SistemaControllerIndex, meta: { title: "CADASTRO DE CONTROLLER" } },
      { path: "/Sistema/Controllers/Edit/:id", name: "SistemaControllerEdit", component: SistemaControllerEdit, meta: { title: "CADASTRO DE CONTROLLER - EDITAR" } },

      //Grupos
      { path: "/Sistema/Grupos/Index", name: "SistemaGrupoIndex", component: SistemaGrupoIndex, meta: { title: "CADASTRO DE GRUPO" } },
      { path: "/sistema/Grupos/Edit/:id", name: "SistemaGrupoEdit", component: SistemaGrupoEdit, meta: { title: "CADASTRO DE GRUPO - EDITAR" } },

      { path: "/Sistema/GruposUsuarios/Index", name: "SistemaGrupoUsuariosIndex", component: SistemaGrupoUsuariosIndex, meta: { title: "CADASTRO DE GRUPO USUÁRIOS" } },
      { path: "/sistema/GruposUsuarios/Edit/:id", name: "SistemaGrupoUsuariosEdit", component: SistemaGrupoUsuariosEdit, meta: { title: "CADASTRO DE GRUPO USUÁRIOS - EDITAR" } },

      { path: "/Sistema/GruposController/Index", name: "SistemaGrupoControllerIndex", component: SistemaGrupoControllerIndex, meta: { title: "CADASTRO DE GRUPO CONTROLLER" } },
      { path: "/sistema/GruposController/Edit/:id", name: "SistemaGrupoControllerEdit", component: SistemaGrupoControllerEdit, meta: { title: "CADASTRO DE GRUPO CONTROLLER - EDITAR" } },

      //Usuarios
      { path: "/Sistema/Usuarios/Index", name: "SistemaUsuarioIndex", component: SistemaUsuarioIndex, meta: { title: "CADASTRO DE USUÁRIO" } },
      { path: "/sistema/Usuarios/Edit/:id", name: "SistemaUsuarioEdit", component: SistemaUsuarioEdit, meta: { title: "CADASTRO DE USUÁRIO - EDITAR" } },

      //Controle de Menu
      { path: "/Sistema/Menus/Index", name: "SistemaMenuIndex", component: SistemaMenuIndex, meta: { title: "CONTROLE DE MENU" } },
      { path: "/sistema/Menus/Edit/:id", name: "SistemaMenuEdit", component: SistemaMenuEdit, meta: { title: "CONTROLE DE MENU - EDITAR" } },

      //Permissões
      { path: "/Sistema/Permissoes/Index", name: "SistemaPermissaoIndex", component: SistemaPermissaoIndex, meta: { title: "CADASTRO DE PERMISSÃO" } },
      { path: "/Sistema/Permissoes/Edit/:id", name: "SistemaPermissaoEdit", component: SistemaPermissaoEdit, meta: { title: "CADASTO DE PERMISSÃO - EDITAR" } },

      //Páginas auxiliares
      { path: "/Login", name: "Login", component: Login, meta: { title: "LOGIN" } },
      { path: "/Logout", name: "Logout", component: Logout, meta: { title: "LOGOUT" } },
      { path: "/about", name: "about", component: About, meta: { title: "SOBRE O SISTEMA" } },
      { path: "/Denied", name: "Denied", component: Denied, meta: { title: "ACESSO NEGADO" } },
      { path: "/Error", name: "Error", component: Error, props: true, meta: { title: "ERRO" } },
      { path: "/Error/{mensagem}", name: "ErrorMsg", component: Error, props: true, meta: { title: "ERRO" } },

      //Rota padrão para página não encontrada
      { path: "*", name: "NotFound", component: NotFound, meta: { title: "PÁGINA NÃO ENCONTRADA" } },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    //Configuração de acesso à rotas públicas e privadas
    //Apenas a página de Login e Logout são públicas
    //Todo o restante requer autenticação no sistema

    //Toda vez que uma rota é acionada [to] receberá o caminho da rota requerida
    
    const reportPages = [];
    const publicPages = ['/Login', '/Logout'];
    const adminPages = [];
    const authRequired = !publicPages.includes(to.path);
    const isReportPage = reportPages.includes(to.path);
    const adminRequired = adminPages.includes(to.path);
    const loggedIn = store.getters.usrLogged;

    store.commit("SET_TITULOTELA",to.meta.title);

    if (!isReportPage)
        store.commit("SET_MODORELATORIO", false);
    
    if (authRequired && !loggedIn) {
        //Redireciona para o login caso uma rota privada seja chamada
        //sem que o usuário esteja autenticado
        return next('/Login');
    } else {
        next();
    }
})

router.afterEach((to, from) => {
    document.title = to.meta.title || "Faturamento";
});

export default router