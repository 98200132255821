import { render, staticRenderFns } from "./ComissionamentoIndex.vue?vue&type=template&id=c6c55b14&scoped=true"
import script from "./ComissionamentoIndex.vue?vue&type=script&lang=js"
export * from "./ComissionamentoIndex.vue?vue&type=script&lang=js"
import style0 from "./ComissionamentoIndex.vue?vue&type=style&index=0&id=c6c55b14&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6c55b14",
  null
  
)

export default component.exports